body {
  margin: 0;
  font-family: "Alata", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

html, body {
  font-size: 10px;
  background-color: #F9FBF8;
  min-height: 100vh; 
}
@keyframes typing {
  0% { width: 0;}
  100% {width: 26ch;}
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
